import 'babel-polyfill';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@styles/style.scss';
import colors from 'vuetify/es5/util/colors';
import pl from './pl';

import VueCompositionApi from '@vue/composition-api';
import Tooltip from '../components/Tooltip';
import ListWrapper from '@app/components/ListWrapper.vue';
import PanelHeader from '@app/components/PanelHeader.vue';
import StatusIcon from '@app/components/StatusIcon.vue';
// import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset';

Vue.use(VueCompositionApi);
Vue.component('Tooltip', Tooltip);
Vue.component('PanelHeader', PanelHeader);
Vue.component('ListWrapper', ListWrapper);
Vue.component('StatusIcon', StatusIcon);

Vue.use(Vuetify, {
    iconfont: 'md',
    lang: {
        locales: { pl },
        current: 'pl',
    },
});

const vtf = new Vuetify({
    // preset,
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 540,
            md: 800,
            lg: 1100,
        },
        scrollBarWidth: 24,
    },
    theme: {
        dark: true,
        themes: {
            light: {
                yellow: colors.yellow.darken1,
                error: colors.red.darken4,
            },
            dark: {
                yellow: colors.yellow.darken2,
                primary: colors.blue.darken2,
                accent: colors.indigo.lighten3,
                success: colors.green.darken2,
            },
        },
        options: {
            customProperties: true,
        },
    },
    lang: {
        locales: { pl },
        current: 'pl',
    },
});
export default vtf;
window.vtf = vtf;
