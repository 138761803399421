import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
if (process.env.SENTRY_VUE_DSN) {
    console.log('sentry:', process.env.SENTRY_VUE_DSN);
    Sentry.init({
        dsn: process.env.SENTRY_VUE_DSN,
        integrations: [
            new VueIntegration({ Vue, attachProps: true, logErrors: true }),
        ],
        tracesSampleRate: process.env.NODE_ENV == 'PRODUCTION' ? 0.1 : 1.0,
    });
}
