import { computed, defineComponent, ref, } from '@vue/composition-api';
function color(type) {
    switch (type) {
        case 'info':
            return 'blue';
        case 'success':
            return 'green';
        case 'warning':
            return 'orange';
        case 'error':
            return 'red';
    }
    return type;
}
async function lock(locked, fn, ...args) {
    locked.value = true;
    try {
        await fn(...args);
        return false;
    }
    catch (error) {
        return error;
    }
    finally {
        locked.value = false;
    }
}
export default defineComponent({
    name: 'ConfirmDialog',
    props: {
        value: { type: Object, required: true },
    },
    setup(props, ctx) {
        const arg = ref(props.value.defaultArg ?? '');
        const visible = ref(true);
        const locked = ref(false);
        const error = ref(null);
        function onEnd() {
            props.value.onEnd ? props.value.onEnd() : void 0;
        }
        async function cancel() {
            if (props.value.onReject) {
                error.value = await lock(locked, props.value.onReject);
            }
            onEnd();
            ctx.emit('close');
        }
        async function confirm() {
            error.value = await lock(locked, props.value.onAccept, arg.value);
            onEnd();
            ctx.emit('close');
        }
        const canAccept = computed(() => {
            if (props.value.canAccept) {
                if (!props.value.canAccept(arg.value)) {
                    return false;
                }
            }
            return true;
        });
        return {
            arg,
            locked,
            error,
            visible,
            canAccept,
            color,
            cancel,
            confirm,
        };
    },
});
