import { api } from '@app/services/api';
export const ConfirmTemplate = {
    delete: {
        title: 'Usuń element',
        message: 'Element zostnie usunięty',
        type: 'error',
        confirmLabel: 'Usuń',
        confirmIcon: 'mdi-delete',
    },
};
const ctrl = {
    alert: (type, title, message, onClose) => { },
    notify: (opt) => { },
    confirm: (opt) => { },
    api: api,
};
export default ctrl;
export const services = ctrl;
window.services = ctrl;
