var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"mr-0 pr-0 ml-0 pl-0",attrs:{"app":"","clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"clipped-right":_vm.$vuetify.breakpoint.lgAndUp,"color":"blue darken-4","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.app.leftDrawer = !_vm.app.leftDrawer}}}),_c('a',{attrs:{"to":"/"}},[_c('img',{staticClass:"mr-2 logo",attrs:{"src":require("@/assets/logo-bw.svg"),"height":"58px","width":"58px"}})]),_c('v-toolbar-title',{staticClass:"hidden-xxxs-only"},[_vm._v(_vm._s(_vm.app.title))]),_c('v-spacer'),_c('Tooltip',{attrs:{"text":"Rozmiar czcionki Normalny"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-xxs-only",attrs:{"icon":""},on:{"click":function($event){return _vm.setSize(0)}}},on),[_vm._v("Aa")])]}}])}),_c('Tooltip',{attrs:{"text":"Rozmiar czcionki Duży"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-xxs-only",attrs:{"icon":""},on:{"click":function($event){return _vm.setSize(1)}}},on),[_vm._v("Aa+")])]}}])}),_c('Tooltip',{attrs:{"text":"Rozmiar czcionki Bardzo duży"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-xxs-only",attrs:{"icon":""},on:{"click":function($event){return _vm.setSize(2)}}},on),[_vm._v("Aa++")])]}}])}),_c('Tooltip',{attrs:{"text":"Wysoki kontrast"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.state.darkTheme = !_vm.state.darkTheme}}},on),[(_vm.state.darkTheme)?_c('v-icon',[_vm._v("mdi-eye-outline")]):_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])}),_c('v-toolbar-items',[(_vm.helpFiles.length > 0)?_c('v-menu',{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Pomoc")])]}}],null,false,3932343896)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.helpFiles),function(help){return _c('v-list-item',{attrs:{"href":help.url,"target":"_blank","disabled":help.url == null}},[_vm._v(_vm._s(help.label))])}),1)],1):_vm._e(),(_vm.user.id > 0)?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"text":"","to":{name: 'dashboard'}}},[_c('v-icon',{staticClass:"pa-1"},[_vm._v("dashboard")]),_vm._v("Dashboard")],1):_vm._e(),(_vm.user.id > 0)?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"text":"","to":{name: 'map'}}},[_c('v-icon',{staticClass:"pa-1"},[_vm._v("map")]),_vm._v("Mapa")],1):_vm._e(),_c('user-dropdown')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }