<template lang="pug">
    v-app
      slot
      slot(name="overlay")
</template>

<script>
export default {
    name: 'BareTemplate',
};
</script>

<style scoped></style>
