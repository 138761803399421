export function priorityColor(priority) {
    return ['info', 'primary', 'warning', 'error'][priority] || 'error';
}
export const prioritiesLabels = [
    'niski',
    'średni',
    'wysoki',
    'krytyczny',
];
export const reportStatuses = {
    ACTIVE: 'przyjęty',
    ACCEPTED: 'zatweirdzony',
    REJECTED: 'odrzucony',
    SOLVED: 'rozwiązany',
};
export const reportTypes = [
    'Niskie ciśnienie',
    'Brak wody',
    'Brudna woda',
    'Zalanie',
];
export const labels = process.env.LABELS;
