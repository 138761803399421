export default {
    close: 'Zamknij',
    dataIterator: {
        pageText: '{0}-{1} z {2}',
        noResultsText: 'Nie znaleziono danych odpowiadających wyszukiwaniu',
        loadingText: 'Wczytywanie danych...',
    },
    dataTable: {
        itemsPerPageText: 'Wierszy na stronie:',
        ariaLabel: {
            sortDescending: ': Sortowanie malejąco. Kliknij aby zmienić.',
            sortAscending: ': Sortowanie rosnąco. Kliknij aby zmienić.',
            sortNone: ': Bez sortowania. Kliknij aby posortować rosnąco.',
        },
    },
    fileInput: {
        counter: 'Licznik',
    },
    dataFooter: {
        itemsPerPageText: 'Pozycji na stronie:',
        itemsPerPageAll: 'Wszystkie',
        nextPage: 'Nastęna strona',
        prevPage: 'Poprzednia strona',
        firstPage: 'Pierwsza strona',
        lastPage: 'Ostatnia strona',
    },
    noDataText: 'Brak danych',
    carousel: {
        prev: 'Poprzedni obraz',
        next: 'Następny obraz',
    },
};
