import UserNoteAdd from './UserNoteAdd.vue';
import { computed, defineComponent, reactive } from '@vue/composition-api';
import axios from 'axios';
export default defineComponent({
    name: 'UserDropdown',
    components: { UserNoteAdd },
    setup(props, ctx) {
        const state = reactive({
            showDialog: false,
            errorMessage: '',
            form: {
                login: '',
                password: '',
            },
            processing: false,
            user: computed(() => ctx.root.$store.state.app.user),
        });
        const login = async () => {
            state.errorMessage = '';
            state.processing = true;
            try {
                const res = await axios.post('/login/', state.form);
                if (res.data.status === 'ok') {
                    state.errorMessage = '';
                    state.form.login = '';
                    state.form.password = '';
                    window.location.reload();
                }
                else {
                    switch (res.data.status) {
                        case 'account disabled':
                            state.errorMessage = 'Konto nieaktywne';
                            break;
                        case 'wrong user or password':
                            state.errorMessage = 'Błędny login lub hasło';
                            break;
                        default:
                            state.errorMessage = res.data.status;
                    }
                }
            }
            catch (error) {
                state.errorMessage = 'Nieznany błąd';
            }
            finally {
                state.processing = false;
            }
        };
        const showUserNote = () => {
            setTimeout(() => {
                state.showDialog = true;
            }, 200);
        };
        return {
            state,
            login,
            showUserNote,
        };
    },
});
