import _ from 'lodash';
import { defineComponent } from '@vue/composition-api';
import { labels } from '@app/globals';
import { assets } from '../../config';
export default defineComponent({
    name: 'Footer',
    setup() {
        const isDev = process.env.NODE_ENV === 'development';
        const logo = _.get(assets, 'logo_small', '');
        return {
            logo,
            isDev,
            version: process.env.VERSION,
            footer: labels.footer ?? 'ZAPSOFT Sp. z o.o. 2020',
        };
    },
});
