import { defineComponent } from '@vue/composition-api';
function alertColor(type) {
    switch (type) {
        case 'info':
            return 'blue';
        case 'success':
            return 'green';
        case 'warning':
            return 'orange';
        case 'error':
            return 'red';
    }
    return 'blue';
}
export default defineComponent({
    name: 'AlertDialog',
    props: {
        alert: Object,
    },
    setup(props, ctx) {
        return { alertColor };
    },
});
