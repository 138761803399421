import UserDropdown from '../UserDropdown.vue';
import { computed, defineComponent, reactive, watch, } from '@vue/composition-api';
export default defineComponent({
    name: 'Toolbar',
    components: { UserDropdown },
    setup(props, ctx) {
        const user = ctx.root.$store.getters['app/user'];
        const root = document.getElementsByTagName('html')[0];
        // root.setAttribute('class', 'a10y--huge');
        const state = reactive({
            darkTheme: true,
            sendLocation: false,
        });
        watch(() => state.darkTheme, () => {
            ctx.root.$vuetify.theme.dark = state.darkTheme;
        });
        const helpFiles = computed(() => {
            const entries = [
                {
                    url: '/static/_docs/Instrukcja_zgloszenia_awarii.pdf',
                    label: 'Zgłoszenia',
                },
            ];
            return entries;
        });
        return {
            state,
            user,
            helpFiles,
            setSize(size) {
                switch (size) {
                    case 0:
                        root.setAttribute('class', '');
                        return;
                    case 1:
                        root.setAttribute('class', 'a10y--big');
                        break;
                    case 2:
                        root.setAttribute('class', 'a10y--huge');
                        break;
                }
            },
        };
    },
    props: {
        app: Object,
    },
});
