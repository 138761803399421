const LayerManage = {
    name: 'manage/layers',
    path: 'layers',
    component: () => import('./LayerManage/LayerManage'),
    meta: {
        template: 'MainTemplate',
    },
};

const MapViews = {
    name: 'manage/map-views',
    path: 'map-views',
    component: () => import('./MapViewManage/MapViewsList'),
    meta: {
        template: 'MainTemplate',
    },
};

const Dictionary = {
    name: 'manage/dictionary',
    path: 'dictionary',
    component: () => import('./Dictionary/DictionaryEditor'),
    meta: {
        template: 'MainTemplate',
    },
};

const Settings = {
    name: 'manage/settings',
    path: 'settings',
    component: () => import('./SystemSettings/SystemSettings'),
    meta: {
        template: 'MainTemplate',
    },
};

const Triggers = {
    name: 'manage/triggers',
    path: 'triggers',
    component: () => import('./Triggers/TriggersView'),
    meta: {
        template: 'MainTemplate',
    },
};

const Main = {
    name: 'manage',
    path: '',
    component: () => import('./ManageMainView'),
    meta: {
        template: 'MainTemplate',
    },
};

export default {
    path: '/manage',
    component: () => import('./ManageLayout'),
    meta: {
        template: 'MainTemplate',
    },
    children: [Main, LayerManage, Dictionary, MapViews, Settings, Triggers],
};
