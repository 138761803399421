/* eslint-disable */
import axios from 'axios';
import * as Cookies from 'js-cookie';
const $api = axios.create({
    baseURL: '/api/',
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
    },
});
$api.push = function (url, item, additional = {}) {
    let id = null;
    if (item.constructor === FormData) {
        if (item.has('id') &&
            item.get('id') !== 'null' &&
            // @ts-ignore
            item.get('id') !== 0) {
            id = item.get('id');
        }
        else {
            id = null;
        }
    }
    else {
        id = item.id;
    }
    if (id == null || id === false || id === 0) {
        return $api.post(`${url}/`, item, additional);
    }
    else {
        return $api.patch(`${url}/${id}/`, item, additional);
    }
};
$api.getCaptchaKey = async function () {
    try {
        const ret = await $api.get('/captcha_key');
        return ret.data.key;
    }
    catch (error) {
        return null;
    }
};
export const geoApi = axios.create({
    baseURL: '/geo',
    headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
    },
});
export const rest = axios.create({});
export const api = $api;
