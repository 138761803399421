import { computed, defineComponent, inject, reactive, } from '@vue/composition-api';
import useHighlight from '@app/utils/useHighlight';
export default defineComponent({
    name: 'ListWrapper',
    props: {
        list: {
            type: Object,
            required: true,
        },
        highlightMap: { type: Boolean, default: false },
        titleField: String,
        subtitleField: String,
        itemOn: Object,
        noItemsInfo: { type: String, default: 'Brak spełniających kryteria' },
        withReport: { type: Boolean, default: false },
        itemProps: { type: Function, default: null },
    },
    setup(props, ctx) {
        const mapController = inject('mapController', null);
        const state = reactive({
            hasMap: mapController != null,
        });
        function getReport(resultType) {
            window.open(props.list.reportUrl(resultType));
        }
        let highlight = props.highlightMap
            ? useHighlight({
                srs: props.list.options.geomSrs ?? 'EPSG:2177',
            })
            : null;
        function mouseOver(item) {
            ctx.emit('item-mouseover', item);
            highlight?.set(item, true);
        }
        function mouseOut(item) {
            ctx.emit('item-mouseout', item);
            highlight?.set(item, false);
        }
        const useVScrollbar = computed(() => props.list.items.value.length > 100);
        return {
            state,
            useVScrollbar,
            mouseOver,
            mouseOut,
            getReport,
            panTo(item) {
                if (mapController == null) {
                    return;
                }
                if (props.list.options?.geomSrs) {
                    mapController.panToGeoJsonGeometry(item.geom, props.list.options.geomSrs);
                }
                else {
                    mapController.panToGeoJsonGeometry(item.geom);
                }
            },
        };
    },
});
