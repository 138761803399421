import { api } from '@app/services/api';
export const state = {
    date: null,
    entries: {},
};
const mutations = {
    setEntries(state, payload) {
        state.entries = payload;
    },
};
const actions = {
    reload({ commit }) {
        return api.get('dictionary/parsed/').then(result => {
            commit('setEntries', result.data);
        });
    },
};
const getters = {
    entries(state) {
        return state.entries;
    },
    entriesFor(state) {
        return (dictionary) => {
            if (dictionary in state.entries) {
                return state.entries[dictionary];
            }
            else {
                return [];
            }
        };
    },
};
const Dictionary = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
export default Dictionary;
