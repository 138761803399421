import { defineComponent, onUnmounted, ref } from '@vue/composition-api';
import { api } from '../services/api';
export default defineComponent({
    name: 'RequestOverlay',
    setup() {
        const showAjaxInfo = ref(0);
        const interceptors = [
            api.interceptors.request.use(config => {
                if (config.method == 'GET' || config.method == 'get') {
                }
                else {
                    showAjaxInfo.value++;
                }
                return config;
            }, error => {
                Promise.reject(error);
            }),
            api.interceptors.response.use(response => {
                if (response.config.method == 'GET' ||
                    response.config.method == 'get') {
                }
                else {
                    showAjaxInfo.value = Math.max(0, showAjaxInfo.value - 1);
                }
                return response;
            }, error => {
                showAjaxInfo.value = Math.max(0, showAjaxInfo.value - 1);
                Promise.reject(error);
            }),
        ];
        onUnmounted(() => {
            interceptors.forEach(id => api.interceptors.eject(id));
        });
        return {
            showAjaxInfo,
        };
    },
});
