import { inject, isRef, onUnmounted } from '@vue/composition-api';
import { getFeatures } from '@app/services/geo';
function isVectorLayer(layer) {
    return 'getFeatures' in layer.getSource();
}
const cache = [];
export default function (opt = {}) {
    const mapController = inject('mapController', null);
    if (mapController == null) {
        return {
            on() { },
            off() { },
            clear() { },
            set() { },
        };
    }
    const list = new Set();
    const highlight = mapController.getService('highlight');
    const on = (feature, id, sourceProj) => {
        if (feature == null) {
            return;
        }
        // if (opt.single) {
        //     clear();
        // }
        highlight.highlight(feature, id, sourceProj);
        list.add(id);
    };
    const off = (id) => {
        highlight.unHighlight(id);
        if (id == null) {
            clear();
        }
        if (id != null) {
            list.delete(id);
        }
    };
    const clear = () => {
        list.forEach(id => off(id));
    };
    onUnmounted(() => {
        clear();
        highlight.unHighlight(null);
    });
    const geomKey = opt?.geomKey ?? 'geom';
    const srs = opt?.srs ?? 'EPSG:4326';
    const getFeatureById = async (ftId) => {
        if (opt.features != null) {
            if (isRef(opt.features)) {
                const ft = opt.features.value.find(ft => ft.id == ftId) ?? null;
                if (ft != null) {
                    return ft;
                }
            }
            else if ('getFeatures' in opt.features) {
                const ft = opt.features
                    .getFeatures()
                    .find(ft => ft.getId() === ftId);
                if (ft != null) {
                    return ft;
                }
            }
            else {
                const ft = opt.features.find(ft => ft.getId() === ftId);
                if (ft != null) {
                    return ft;
                }
            }
        }
        {
            const vectorSources = mapController.map
                .getLayers()
                .getArray()
                .filter(isVectorLayer)
                .map(vectorLayer => vectorLayer.getSource());
            console.log(vectorSources);
            for (const source of vectorSources) {
                const ft = source.getFeatureById(ftId);
                if (ft != null) {
                    return ft;
                }
            }
        }
        {
            // try WFS
            const res = cache.find(([id]) => id == ftId);
            try {
                const wfsRes = await getFeatures([ftId], srs);
                if (wfsRes.features.length == 1) {
                    cache.push([ftId, wfsRes.features[0]]);
                    if (cache.length > 1000) {
                        cache.splice(0);
                    }
                }
                return res ? res[1] : null;
            }
            catch (error) {
                return null;
            }
        }
        return null;
    };
    const set = async (item, set) => {
        if (typeof item == 'string') {
            if (set) {
                const ft = await getFeatureById(item);
                if (ft != null) {
                    if ('geometry' in ft) {
                        on(ft.geometry, item, srs);
                    }
                    else {
                        on(ft.getGeometry(), item, srs);
                    }
                }
            }
            else {
                off(item);
            }
            return;
        }
        if (item != null && item[geomKey] != null) {
            const id = `report:${item.id.toString()}`;
            if (set) {
                on(item[geomKey], id, srs);
            }
            else {
                off(id);
            }
        }
        else {
            off(null);
        }
    };
    return {
        on,
        off,
        clear,
        set,
    };
}
