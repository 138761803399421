<template lang="pug">
    div.main
        .center
            img(src="@/assets/logo-bw.svg" width="50%")
</template>

<script>
export default {
    name: 'SplashScreen',
};
</script>

<style scoped>
.main {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
}
.center {
    vertical-align: middle;
}
</style>
