import Vue from 'vue';
import Vuex from 'vuex';
// import messages from './modules/messages';
import dictionary from './modules/dictionary';
import MainStore from '@app/store/modules/MainStore';
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        app: MainStore,
        dictionary: dictionary,
    },
});
