import Toolbar from '../../components/main/Toolbar.vue';
import BareTemplate from './BareTemplate.vue';
import { computed, defineComponent, onUnmounted, reactive, ref, watch, } from '@vue/composition-api';
import services from '@app/apps/services';
import * as Cookies from 'js-cookie';
import RequestOverlay from '../../components/RequestOverlay.vue';
import AlertDialog from '../../components/overlays/AlertDialog.vue';
import ConfirmDialog from '../../components/overlays/ConfirmDialog.vue';
const menu = {
    groups: [
        {
            label: 'Przegląd',
            items: [
                {
                    label: 'Tablica',
                    url: '/',
                    icon: 'home',
                },
            ],
        },
        {
            label: 'Wydarzenia',
            items: [
                {
                    label: 'Zgłoszenia',
                    url: '/reports',
                    icon: 'report',
                },
                {
                    label: 'Awarie',
                    url: '/failures',
                    icon: 'report_problem',
                },
                {
                    label: 'Naprawy',
                    url: '/maintenance',
                    icon: 'build',
                },
            ],
        },
        {
            items: [
                {
                    label: 'Inspekcje',
                    url: '/inspections',
                    icon: 'mdi-magnify',
                },
                {
                    label: 'Przeglądy',
                    url: '/overview',
                    icon: 'mdi-check',
                },
            ],
        },
    ],
};
export default defineComponent({
    name: 'Main',
    components: { Toolbar, RequestOverlay, AlertDialog, ConfirmDialog },
    setup(props, ctx) {
        const showCookie = ref((Cookies.get('cookie-acceptance') ?? 'false') != 'true');
        ctx.root.menu = menu;
        const user = ctx.root.$store.getters['app/user'];
        const app = reactive({
            leftDrawer: true,
            rightDrawer: false,
            title: 'Tablica',
            alerts: [],
            confirms: [],
            notifications: [],
        });
        const template = computed(() => {
            const tmp = ctx.root.$route.meta.template;
            if (tmp) {
                return tmp;
            }
            return BareTemplate;
        });
        const transitionName = ref('fade');
        // watch(route, (to, from) => {
        //     const toDepth = to.path.split('/').length;
        //     const fromDepth = from.path.split('/').length;
        //     if (toDepth === fromDepth) {
        //         transitionName.value = 'fade';
        //     } else {
        //         transitionName.value =
        //             toDepth < fromDepth ? 'slide-right' : 'slide-left';
        //     }
        // });
        let alertId = 0;
        const alertHandle = alert;
        const addAlert = (type, title, message, onClose) => {
            app.alerts = app.alerts.filter(alert => alert.visible);
            const alert = {
                type,
                title,
                message,
                id: alertId++,
                visible: true,
            };
            app.alerts.push(alert);
            const idx = app.alerts.findIndex(i => i.id == alert.id);
            if (onClose) {
                let watcher = watch(() => app.alerts[idx].visible, (visible) => {
                    if (!visible) {
                        watcher();
                        onClose();
                    }
                });
            }
        };
        services.alert = addAlert;
        // window.onerror = event => {
        //     console.log(event);
        //     addAlert('error', 'Błąd', JSON.stringify(event, null, 2));
        // };
        window.alert = (message) => {
            if (typeof message == 'string') {
                addAlert('info', 'Informacja', message);
            }
            else {
                addAlert(message.type, message.title, message.message);
            }
        };
        onUnmounted(() => {
            if (!module.hot) {
                window.alert = alertHandle;
                services.alert = () => { };
            }
        });
        const addConfirm = (opt) => {
            console.log(opt);
            app.confirms.push({
                ...opt,
                id: alertId++,
            });
        };
        services.confirm = addConfirm;
        // notifications
        const notify = (opt) => {
            const item = {
                id: alertId++,
                ...opt,
            };
            app.notifications.push(item);
            setTimeout(() => {
                const idx = app.notifications.indexOf(item);
                app.notifications.splice(idx, 1);
            }, opt.duration ?? 5000);
        };
        onUnmounted(() => {
            if (!module.hot) {
                services.notify = () => { };
            }
        });
        services.notify = notify;
        return {
            app,
            template,
            transitionName,
            showCookie,
            acceptCookie() {
                showCookie.value = false;
                Cookies.set('cookie-acceptance', 'true');
            },
        };
    },
});
