<template lang="pug">
    v-app(dark)
        Toolbar(:app="app")
        v-navigation-drawer(app :clipped="$vuetify.breakpoint.lgAndUp" fixed v-model="app.leftDrawer")
            v-list(dense)
                template(v-for="group in $options.menu.groups")
                    div(v-if="group.enabled")
                        v-subheader {{group.label}}
                        v-list-item(
                            v-for="item in group.items"
                            :key="item.url"
                            :to="item.url"
                        )
                            v-list-item-action
                                v-icon {{item.icon}}
                            v-list-item-title {{item.label}}
        v-main
            transition(:name="transitionName")
                slot
        Footer
        slot(name="overlay")
</template>

<script>
import Toolbar from '../../components/main/Toolbar';
import Footer from '../../components/main/Footer';
import { defineComponent } from '@vue/composition-api';

let json = require('config.json');
const menu = json.dashboard_menu;

export default defineComponent({
    name: 'MainTemplate',
    components: { Toolbar, Footer },
    menu,
    data() {
        return {
            app: {
                leftDrawer: true,
                rightDrawer: false,
                title: 'Tablica',
            },
        };
    },
    created() {
        console.log('create');
        this.transitionName = 'fade';
    },
    watch: {
        $route(to, from) {
            const toDepth = to.path.split('/').length;
            const fromDepth = from.path.split('/').length;
            if (toDepth === fromDepth) {
                this.transitionName = 'fade';
            } else {
                this.transitionName =
                    toDepth < fromDepth ? 'slide-right' : 'slide-left';
            }
        },
    },
});
</script>

<style scoped></style>
