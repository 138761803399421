var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.mainComponent,{tag:"component",staticClass:"pr-0",staticStyle:{"position":"relative"},attrs:{"dense":"","color":"blue darken-4","dark":"","tile":""},scopedSlots:_vm._u([(_vm.list != null)?{key:"extension",fn:function(){return [_c('v-toolbar-items',{staticClass:"left d-flex"},[(_vm.list.filter != null)?_c('v-menu',{attrs:{"left":"","offset-x":"","max-width":"400px","min-width":"400px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"flex":"1"},attrs:{"icon":"","color":(_vm.state.hasFilters) ? 'green' : ''}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,true)},[_c('span',[_vm._v("Filtruj")])])]}}],null,false,2795189549),model:{value:(_vm.state.showFilter),callback:function ($$v) {_vm.$set(_vm.state, "showFilter", $$v)},expression:"state.showFilter"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_vm._t("filter")],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('filter-clear')}}},[_vm._v("Wyczyść")]),_c('v-btn',{attrs:{"outlined":"","color":"success"},on:{"click":function($event){$event.stopPropagation();_vm.state.showFilter = false}}},[_vm._v("Zamknij")])],1)],1)],1):_vm._e()],1),_c('v-select',{staticClass:"mr-2",staticStyle:{"width":"6rem"},attrs:{"label":"Pozycji","items":[5, 10, 20, 50, 100, 200, 500],"hide-details":""},model:{value:(_vm.list.state.limit),callback:function ($$v) {_vm.$set(_vm.list.state, "limit", _vm._n($$v))},expression:"list.state.limit"}}),(_vm.list.order != null)?_c('div',{staticStyle:{"flex":"1"}},[_c('v-select',{attrs:{"label":"Sortuj","items":_vm.orderList,"hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [(typeof item.text == 'string')?_c('span',[_vm._v(_vm._s(item.text))]):_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text[0]))]),(item.text[1].length > 0)?_c('v-list-item-subtitle',[_vm._v(_vm._s(item.text[1]))]):_vm._e()],1)]}}],null,false,2664869336),model:{value:(_vm.list.order.value),callback:function ($$v) {_vm.$set(_vm.list.order, "value", $$v)},expression:"list.order.value"}})],1):_vm._e()]},proxy:true}:null],null,true)},[(_vm.mode=='subview')?_c('v-toolbar-items',{staticClass:"left"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.list != null)?_c('Tooltip',{attrs:{"text":"Odśwież","side":"bottom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [(_vm.list)?_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":""},on:{"click":_vm.list.reload}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e()]}}],null,false,4016447350)}):_vm._e(),(_vm.createAvailable !== false && _vm.list != null)?_c('Tooltip',{attrs:{"text":"Dodaj","side":"bottom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0",attrs:{"data-testid":"PanelHeader.addButton","icon":"","disabled":!(_vm.createAvailable === true)},on:{"click":function($event){return _vm.$emit('create')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3832619603)}):_vm._e(),(_vm.mode=='dialog')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }