import { computed, defineComponent } from '@vue/composition-api';
import _ from 'lodash';
import { HydrantStatuses, iconsets, ReportIcons, StatusIcons, } from '@app/db/models';
function getIconSet(type) {
    switch (type) {
        case 'report':
            return ReportIcons;
        case 'hydrant-status':
            return HydrantStatuses;
        default:
            return StatusIcons;
    }
}
export default defineComponent({
    name: 'StatusIcon',
    props: {
        status: { type: [Number, String], required: true },
        type: {
            type: String,
            default: null,
        },
        label: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const iconset = props.type in iconsets ? iconsets[props.type] : iconsets.failure;
        const prefix = iconset.iconset.length > 0 ? iconset.iconset + '-' : '';
        const icon = computed(() => {
            const colors = iconset.colors;
            const idx = typeof props.status == 'string'
                ? (() => {
                    return (iconset.values ?? iconset.labels).findIndex(label => label == props.status);
                })()
                : props.status;
            const status = _.clamp(idx, 0, colors.length) | 0;
            return {
                color: colors[status],
                icon: iconset.icons[status],
                tooltip: iconset.labels[status],
            };
        });
        return {
            prefix,
            icon,
        };
    },
});
