import { geoApi } from './api';
export function getAddress({ point, srid = 4326 }) {
    return geoApi.post('' + 'find_address/', {
        point,
        srid,
    });
}
const layerCache = new Map();
export async function getLayer(layer, srsName = 'EPSG:2177', params) {
    const urlCache = `/get_layer/${layer}/${srsName}/${JSON.stringify(params)}`;
    const url = `/get_layer/${layer}/${srsName}/`;
    if (layerCache.has(urlCache)) {
        const layer = layerCache.get(urlCache);
        if (layer != null) {
            return layer;
        }
        throw new Error('Layer data not found');
    }
    try {
        const result = (await geoApi.get(url, {
            params,
        })).data;
        layerCache.set(urlCache, result);
        return result;
    }
    catch (error) {
        layerCache.set(urlCache, null);
        throw new Error('Layer data not found');
    }
}
export async function getFeatures(featureIds, srs) {
    // const layers = new Set(featureIds.map(id => id.split('.')[0]));
    // geoApi.get<GeoJSONFeatureCollection>('/wfs', {
    //     params: {
    //
    //     }
    // })
    const res = await geoApi.get('/get_features', {
        params: {
            ids: featureIds.join(','),
            srsName: srs,
        },
    });
    return res.data;
}
export function getWfs(params) {
    return geoApi.get('wfs', {
        params,
    });
}
export function getProfile(points, projection) {
    return geoApi.get('/get_height', {
        params: {
            projection,
            points: JSON.stringify(points),
        },
    });
}
// export default {
//     getFeatures,
//     getAddress,
//     getLayer,
//     getSldStyle,
//     getWfs,
//     getProfile,
// };
