import Vue from 'vue';
import Vuex from 'vuex';
import '@/style.scss';
import axios from 'axios';
import VueNativeSock from 'vue-native-websocket';
import _ from 'lodash';
import { geoApi, api } from '../services/api';
import '@mdi/font/scss/materialdesignicons.scss';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pl';
import '@/plugins/sentry';

dayjs.locale('pl');

const dateFilter = (v, format) => {
    try {
        const m = dayjs(v);
        if (format === 'cal') {
            return m.calendar();
        }
        if (format != null) {
            return m.format(format);
        } else {
            return m.format('L');
        }
    } catch (error) {
        console.error(error);
        return '-ERR-';
    }
};

Vue.filter('moment', dateFilter);
Vue.filter('dayjs', dateFilter);

Vue.prototype.$axios = axios;

axios.get('/get-token/').then(response => {
    axios.defaults.headers.common['X-CSRFToken'] = response.data.token;
});

Vue.prototype.$api = api;
Vue.prototype.$geo = geoApi;

Vue.use(VueNativeSock, process.env.WS_URL, {
    format: 'json',
    reconnection: true,
});

const ws = new Vue({
    created() {
        this.$options.sockets.onmessage = event => {
            const data = JSON.parse(event.data);
            if (process.env.NODE_ENV === 'development') {
                console.log('Received WS: ', event);
            }
            this.$emit(data.type, _.get(data, 'message', void 0));
        };
    },
    methods: {
        send(type, message) {
            this.$options.sockets.sendObj({
                type,
                message,
            });
        },
    },
});

Vue.prototype.$ws = ws;
Vue.mixin({
    mounted() {
        if (this.$options.ws) {
            Object.keys(this.$options.ws).forEach(event => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Subscribe ', event);
                }
                this.$wsEvent = {};
                if (event !== 'subscribe' && event !== 'unsubscribe') {
                    this.$wsEvent[event] = this.$options.ws[event].bind(this);
                    this.$ws.$on(event, this.$wsEvent[event]);
                }
            });
        }
    },
    beforeDestroy() {
        if (this.$options.ws) {
            Object.keys(this.$options.ws).forEach(event => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Unsubscribe ', event);
                }
                if (event !== 'subscribe' && event !== 'unsubscribe') {
                    this.$ws.$off(event, this.$wsEvent['_' + event]);
                }
            });
        }
    },
});

if ('serviceWorker' in navigator) {
    let url;
    if (process.env.NODE_ENV === 'development') {
        url = `${window.location.origin}/service-worker.js`;
    } else {
        url = '/service-worker.js';
    }
    navigator.serviceWorker
        .register(url)
        .then(() => {
            console.log('Service Worker Registered');
        })
        .catch(error => {
            console.error(error);
        });
}

Vue.use(Vuex);
Vue.config.productionTip = false;
