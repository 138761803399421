<template lang="pug">
    v-card
        v-card-text
            v-text-field(v-model="form.title" label="Tytuł")
            v-textarea(v-model="form.content" label="Notatka")
        v-card-actions
            v-spacer
            v-btn(@click="close" color="warning" text) Zaniechaj
            v-btn(@click="add" color="success" text) Dodaj
</template>

<script>
export default {
    name: 'UserNoteAdd',
    data: () => ({
        form: {
            title: '',
            content: '',
        },
    }),
    methods: {
        collectData() {
            const data = {};
            if (this.$store.state.map) {
                data.mapState = {
                    mapState: this.$store.state.map.mapState,
                };
            }
            return data;
        },
        close() {
            this.form.content = '';
            this.form.title = '';
            this.$emit('close');
        },
        async add() {
            await this.$api.post('/user_notes/', {
                ...this.form,
                user: window.user.id,
                data: this.collectData(),
            });
            this.form.content = '';
            this.form.title = '';
            this.$emit('close');
        },
    },
};
</script>

<style scoped></style>
