import _ from 'lodash';
function camelize(obj) {
    return _.mapKeys(obj, (v, k) => _.camelCase(k));
}
const module = {
    namespaced: true,
    state: {
        user: camelize(window.user),
        dictionary: {},
    },
    getters: {
        user(state) {
            return state.user;
        },
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
    },
    actions: {
        setUser(context, userData) {
            const user = _.mapKeys(userData, (v, k) => _.camelCase(k));
            context.commit('SET_USER', user);
        },
    },
};
export default module;
