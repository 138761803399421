import Vue from 'vue';

import Vuex from 'vuex';
import View from './Main.vue';
import vuetify from '../../plugins/vuetify';
import router from './routes';
import MainStore from '../../store/modules/MainStore';
import MainTemplate from './MainTemplate';
import BareTemplate from './BareTemplate';
import dictionary from '../../store/modules/dictionary';
import SplashScreen from './SplashScreen';
require('../base');

const store = new Vuex.Store({
    modules: {
        app: MainStore,
        dictionary: dictionary,
    },
});
Vue.component('MainTemplate', MainTemplate);
Vue.component('SplashScreen', SplashScreen);
Vue.component('BareTemplate', BareTemplate);

new Vue({
    render: h => h(View),
    store,
    vuetify,
    router,
    async created() {
        window.store = this.$store;
        await this.$store.dispatch('dictionary/reload');
    },
}).$mount('#app');
