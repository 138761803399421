export const Status = {
    NEW: 0,
    ASSIGNED: 1,
    PROGRESS: 2,
    SUCCESS: 3,
    REJECTED: 4,
};
export const Statuses = [
    { text: 'Zaplanowany', value: Status.NEW },
    { text: 'Rozpoczęty', value: Status.PROGRESS },
    { text: 'Odrzucony', value: Status.REJECTED },
    { text: 'Zakończony', value: Status.SUCCESS },
];
export const StatusesRenovation = [
    { text: 'Zaplanowany', value: Status.NEW },
    { text: 'Rozpoczęty', value: Status.PROGRESS },
    { text: 'Odrzucony', value: Status.REJECTED },
    { text: 'Zakończony', value: Status.SUCCESS },
];
export const StatusesReport = [
    { text: 'Nowy', value: Status.NEW, color: 'yellow' },
    { text: 'Przypisany', value: Status.ASSIGNED },
    { text: 'Rozpoczęty', value: Status.PROGRESS },
    { text: 'Odrzucony', value: Status.REJECTED },
    { text: 'Zakończony', value: Status.SUCCESS },
];
export const StatusColors = ['yellow', 'blue', 'blue', 'green', 'red'];
const StatusLabels = [
    'Nowy',
    'Przypisany',
    'Rozpoczęty',
    'Zakończony',
    'Odrzucony',
];
export const ReportIcons = [
    'report-new',
    'report-assigned',
    'report-progress',
    'report-success',
    'report-rejected',
];
export const StatusIcons = [
    'error',
    'pause_circle_filled',
    'play_circle_filled',
    'check_circle',
    'cancel',
];
export const ReportStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    labels: StatusLabels,
    colors: StatusColors,
    icons: [
        'report-new',
        'report-assigned',
        'report-progress',
        'report-success',
        'report-rejected',
    ],
};
export const FailureStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    labels: StatusLabels,
    colors: StatusColors,
    icons: [
        'failure-new',
        'failure-assigned',
        'failure-progress',
        'failure-success',
        'failure-rejected',
    ],
};
export const RenovationStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    colors: StatusColors,
    labels: [
        'Zaplanowany',
        'Zaplanowany',
        'Rozpoczęty',
        'Zakończony',
        'Odrzucony',
    ],
    icons: [
        'renovation-new',
        'renovation-assigned',
        'renovation-progress',
        'renovation-success',
        'renovation-rejected',
    ],
};
export const InspectionStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    colors: ['gray'],
    labels: ['Inspekcja'],
    icons: ['video'],
};
export const HydrantInspectionStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    labels: ['Ważny', 'Nieważny', 'Brak'],
    values: ['Ważny', 'Nieważny', 'Brak'],
    colors: ['success', 'error', 'secondary'],
    icons: ['fire-hydrant', 'fire-hydrant', 'fire-hydrant'],
};
export const HydrantStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    labels: ['Sprawny', 'Niesprawny', 'Brak danych'],
    values: ['Sprawny', 'Niesprawny', ''],
    colors: ['success', 'error', 'secondary'],
    icons: ['record-circle', 'record-circle', 'record-circle'],
};
export const MapSketchStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    labels: ['O', 'Z'],
    values: [0, 1],
    colors: ['yellow', 'error'],
    icons: ['note', 'note'],
};
export const EasementStatuses = {
    iconset: 'ugisfont',
    fontName: 'ugisfont',
    labels: ['Ustanowiona', 'Nie ustanowiona', 'W trakcie'],
    values: ['Ustanowiona', 'Nie ustanowiona', 'W trakcie'],
    colors: StatusColors,
    icons: [''],
};
export const iconsets = {
    report: ReportStatuses,
    failure: FailureStatuses,
    renovation: RenovationStatuses,
    'hydrant-status': HydrantStatuses,
    'hydrant-inspection': HydrantInspectionStatuses,
};
export function registerIconset(name, iconset) {
    iconsets[name] = iconset;
}
export const Priorities = [
    { text: 'Niski', value: 0 },
    { text: 'Średni', value: 1 },
    { text: 'Wysoki', value: 2 },
    { text: 'Krytyczny', value: 3 },
];
const ReportFilter = [
    {
        label: 'Numer',
        field: 'number',
        filter: {
            type: 'text',
            comparison: 'ilike',
        },
    },
    {
        label: 'Status',
        field: 'status',
        filter: {
            type: 'enum',
            choices: ['ACTIVE', 'PENDING', 'REJECTED', 'FIXED'],
        },
    },
];
export default null;
