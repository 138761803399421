import { defineComponent, reactive, computed, } from '@vue/composition-api';
import Tooltip from '@app/components/Tooltip.vue';
import { VToolbar } from 'vuetify/lib';
export default defineComponent({
    name: 'PanelHeader',
    components: { Tooltip },
    props: {
        title: { type: String },
        order: { type: [Object, Array] },
        list: { type: Object, default: null },
        withControl: { type: Boolean, default: true },
        createAvailable: { type: [String, Boolean], default: true },
        mode: { type: String, default: null },
    },
    setup(props, ctx) {
        const mainComponent = props.mode == 'dialog' ? VToolbar : VToolbar;
        const state = reactive({
            showFilter: false,
            extended: true,
            hasFilters: props.list?.isFiltered ?? false,
        });
        const orderList = computed(() => {
            if (Array.isArray(props.order)) {
                return props.order;
            }
            else if (props.order != null) {
                return Object.entries(props.order).map(([text, value]) => ({
                    text,
                    value,
                }));
            }
            return [];
        });
        return {
            mainComponent,
            state,
            orderList,
        };
    },
});
